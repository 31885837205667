import {
  Box,
  Flex,
  Heading,
  Image,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { curryIncludesInsensitive } from "utils/includesInsensitive"
import {
  getAppointmentReason,
  SelectedExerciceType,
  TrainingVideoGroupsType,
  VideoGroupCategoryType,
  VideoType,
} from "api/appointmentReason"
import {
  PATHOLOGIES_MOCK,
  ZonePathologieType,
  ZONES_MOCK,
} from "pages/NewAppointment/newAppointment.mock"
import Header from "./components/Header/Header"
import arrowBack from "../../../../assets/arrow_back.svg"

import SelectedExercices from "./components/SelectedExercices/SelectedExercices"
import SuggestedProgramme from "./components/SuggestedProgramme/SuggestedProgramme"
import { useQuery } from "@tanstack/react-query"
import { ReasonType } from "api/patient"
import Reason from "pages/NewAppointment/components/SelectExercices/components/Reason"
import BabyMediaTags from "pages/NewAppointment/components/SelectReason/components/BabyMediaTags/BabyMediaTags"
import SelectedBabyMediaTags from "pages/NewAppointment/components/SelectReason/components/SelectedBabyMediaTags/SelectedBabyMediaTags"
import SelectMainReason from "pages/NewAppointment/components/SelectExercices/components/SelectMainReason"
import { BabyMediaTag } from "api/media"
import { getCommonPrograms, getMyPrograms, ProgramType } from "api/program"
import { TimeIcon } from "@chakra-ui/icons"
import { getTotalDuration } from "utils/getTotalDuration"
import AddProgramModal from "pages/NewAppointment/components/SelectExercices/components/AddProgramModal/AddProgramModal"
import ZonesPathologies from "pages/Exercices/components/ExercicesLists/components/ZonesPathologies/ZonesPathologies"
import TabExercices from "pages/Exercices/components/ExercicesLists/components/TabExecices/TabExercices"
import FilterExercices from "pages/Exercices/components/ExercicesLists/components/FilterExercices/FilterExercices"
import FilterPrograms from "pages/Exercices/components/ExercicesLists/components/FilterPrograms/FilterPrograms"
import VideoGroup from "pages/Exercices/components/ExercicesLists/components/VideoGroup/VideoGroup"
import ProgramCard from "pages/Exercices/components/ExercicesLists/components/ProgramCard/ProgramCard"
import { niceDisplayVideoGroup } from "utils/niceDisplayVideoGroup"

const sortByTimeUsed = (
  a: TrainingVideoGroupsType,
  b: TrainingVideoGroupsType
) => {
  if (a.timeUsed < b.timeUsed) {
    return 1
  }
  if (a.timeUsed > b.timeUsed) {
    return -1
  }
  return 0
}

export const VIDEOS_TAB = 0
export const PROGRAMME_TAB = 1
export const FAVORIS_TAB = 2

// COMPONENTS
interface IProps {
  setStep: React.Dispatch<React.SetStateAction<number>>
  reason: ZonePathologieType | undefined
  selectedExercices: SelectedExerciceType[]
  setSelectedExercices: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
  reasonType: "zones" | "pathologies" | null
  setReasonType: (reason: "zones" | "pathologies" | null) => void
  setReason: React.Dispatch<
    React.SetStateAction<ZonePathologieType | undefined>
  >
  isBabySwitchOn: boolean
  setIsBabySwitchOn: React.Dispatch<React.SetStateAction<boolean>>
  selectedBabyMediaTag: BabyMediaTag | null
  setSelectedBabyMediaTag: React.Dispatch<
    React.SetStateAction<BabyMediaTag | null>
  >
  babyMediaTags: BabyMediaTag[]
}

const SelectExercices: React.FC<IProps> = ({
  setStep,
  reasonType,
  setReasonType,
  selectedExercices,
  setSelectedExercices,
  reason,
  setReason,
  isBabySwitchOn,
  setIsBabySwitchOn,
  selectedBabyMediaTag,
  setSelectedBabyMediaTag,
  babyMediaTags,
}) => {
  const [search, setSearch] = useState("")
  const [suggestedProgramme, setSuggestedProgramme] = useState<VideoType[]>([])
  const [videoGroups, setVideoGroups] = useState<TrainingVideoGroupsType[]>([])
  const [categories, setCategories] = useState<VideoGroupCategoryType[]>([])
  const [categoryFilter, setCategoryFilter] = useState<string | null>(null)
  const toast = useToast()

  const [restrictionFilter, setRestrictionFilter] = useState<string | null>(
    null
  )
  const [programs, setPrograms] = useState<ProgramType[]>([])
  const [commonPrograms, setCommonPrograms] = useState<ProgramType[]>([])
  const [selectedProgram, setSelectedProgram] = useState<ProgramType | null>(
    null
  )
  const [likedVideoGroups, setLikedVideoGroups] = useState<
    TrainingVideoGroupsType[]
  >([])

  const [selectedTab, setSelectedTab] = useState(VIDEOS_TAB)

  const onReturn = () => {
    if (setStep) {
      setStep(0)
    }
  }

  const restrictions = videoGroups.reduce(
    (acc, { restrictions }) => [
      ...acc,
      ...(restrictions ?? []).reduce(
        (restrictionsAcc, { name }) =>
          acc.includes(name) ? restrictionsAcc : [...restrictionsAcc, name],
        [] as string[]
      ),
    ],
    [] as string[]
  )

  const fetchDataForReason = async (reason: ReasonType | null) => {
    const { data } = await getAppointmentReason(reason?.id ?? -1)
    setSuggestedProgramme(data.suggestedProgramme)
    setVideoGroups(data.trainingVideoGroups)
    setLikedVideoGroups(
      data.trainingVideoGroups.filter(
        (trainingVideoGroup) =>
          trainingVideoGroup.osteosLiking &&
          trainingVideoGroup.osteosLiking.length > 0
      )
    )
  }

  const fetchMyPrograms = async () => {
    const programsResponse = await getMyPrograms()
    const commonProgramsResponse = await getCommonPrograms()
    setPrograms(programsResponse)
    setCommonPrograms(commonProgramsResponse)
  }

  useEffect(() => {
    fetchMyPrograms()
  }, [])

  useEffect(() => {
    fetchDataForReason(reason ?? null)
  }, [reason, selectedTab])

  useEffect(() => {
    const listOfCategories = videoGroups
      .map((videoGroup) => videoGroup.category)
      .filter((elt) => elt !== null)

    const uniqueCategories = listOfCategories.filter(
      (category, index) => listOfCategories.indexOf(category) === index
    )

    setCategories(uniqueCategories)
  }, [videoGroups])

  useEffect(() => {
    // reset filters
    setCategoryFilter(null)
    setSearch("")
  }, [reason])

  const filterWithCategoriesRestrictionsAndSearch = (
    list: TrainingVideoGroupsType[]
  ) => {
    const curriedIncludesInsensitive = curryIncludesInsensitive(search)
    let filteredList = list
    if (search !== "") {
      filteredList = filteredList.filter(({ title }) =>
        curriedIncludesInsensitive(title)
      )
    }
    if (categoryFilter !== null) {
      filteredList = filteredList.filter(
        ({ category }) => category === categoryFilter
      )
    }
    if (restrictionFilter !== null) {
      filteredList = filteredList.filter(({ restrictions }) =>
        restrictions.some(({ name }) => name === restrictionFilter)
      )
    }
    return filteredList
  }

  const filterPrograms = (list: ProgramType[]) => {
    const newList = list.filter(
      (program) =>
        program.trainingVideoPrograms &&
        program.trainingVideoPrograms.length > 0
    )
    const curriedIncludesInsensitive = curryIncludesInsensitive(search)
    let filteredList = newList
    if (search !== "") {
      filteredList = filteredList.filter(({ title }) =>
        curriedIncludesInsensitive(title)
      )
    }
    if (reason) {
      filteredList = filteredList.filter(({ appointmentReasons }) =>
        appointmentReasons.find(
          (currentReason) => currentReason.id === reason.id
        )
      )
    }
    return filteredList
  }

  const [sortedFilteredVideoGroups, setSortedFilteredVideoGroups] = useState<
    TrainingVideoGroupsType[]
  >([])
  const [sortedFilteredLikedVideoGroups, setSortedFilteredLikedVideoGroups] =
    useState<TrainingVideoGroupsType[]>([])

  const [sortedFilteredPrograms, setSortedFilteredPrograms] = useState<
    ProgramType[]
  >([])
  const [sortedCommonPrograms, setSortedCommonPrograms] = useState<
    ProgramType[]
  >([])

  useEffect(() => {
    setSortedFilteredVideoGroups(
      niceDisplayVideoGroup(
        filterWithCategoriesRestrictionsAndSearch(
          videoGroups.sort(sortByTimeUsed)
        )
      )
    )
    setSortedFilteredLikedVideoGroups(
      niceDisplayVideoGroup(
        filterWithCategoriesRestrictionsAndSearch(
          likedVideoGroups.sort(sortByTimeUsed)
        )
      )
    )
  }, [videoGroups, categoryFilter, restrictionFilter, search])

  useEffect(() => {
    const filteredPrograms = filterPrograms(programs)
    setSortedFilteredPrograms(filteredPrograms)
  }, [search, reason, programs])

  useEffect(() => {
    const filteredProgram = filterPrograms(commonPrograms)
    setSortedCommonPrograms(filteredProgram)
  }, [search, reason, commonPrograms])

  return (
    <Box p="16px" width="full" pb={"120px"}>
      <Flex>
        <Flex direction="column" pos="sticky" top={4} h="fit-content">
          <Flex
            mb={6}
            cursor="pointer"
            onClick={onReturn}
            p="8px"
            bgColor="primary.200"
            w="fit-content"
            display="block"
            rounded="9px"
            pos="sticky"
            top={4}
          >
            <Image src={arrowBack} width="24px" height="24px" />
          </Flex>
          <Reason
            reason={reasonType}
            setReason={setReasonType}
            isBabySwitchOn={isBabySwitchOn}
          />
          {isBabySwitchOn ? (
            <Flex direction={"column"}>
              <BabyMediaTags
                selectedBabyMediaTag={selectedBabyMediaTag}
                setSelectedBabyMediaTag={setSelectedBabyMediaTag}
                babyMediaTags={babyMediaTags}
              />
              {selectedBabyMediaTag && (
                <SelectedBabyMediaTags
                  selectedBabyMediaTag={selectedBabyMediaTag}
                  setSelectedBabyMediaTag={setSelectedBabyMediaTag}
                />
              )}
            </Flex>
          ) : (
            <>
              <Flex alignItems={"center"}>
                <Flex justifyContent="center" w="full">
                  {reasonType === "zones" && (
                    <ZonesPathologies
                      datas={ZONES_MOCK}
                      selectedData={reason}
                      setSelectedData={setReason}
                    />
                  )}
                  {reasonType === "pathologies" && (
                    <ZonesPathologies
                      datas={PATHOLOGIES_MOCK}
                      selectedData={reason}
                      setSelectedData={setReason}
                    />
                  )}
                </Flex>
              </Flex>
            </>
          )}
        </Flex>
        <Box flex={1} px="60px" position="relative">
          <TabExercices
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            isPageExercies
          />
          {selectedTab === 0 && (
            <FilterExercices
              categories={categories}
              categoryFilter={categoryFilter}
              setCategoryFilter={setCategoryFilter}
              setSearch={setSearch}
              restrictions={restrictions}
              restrictionFilter={restrictionFilter}
              setRestrictionFilter={setRestrictionFilter}
            />
          )}
          {selectedTab === 1 && <FilterPrograms setSearch={setSearch} />}

          <Flex justifyContent="flex-start" flexWrap="wrap" gap="16px" mt={4}>
            {selectedTab === 0 &&
              sortedFilteredVideoGroups.map((videoGroup, index) => (
                <VideoGroup
                  key={`video_group_${videoGroup.id}_${index}`}
                  videoGroup={videoGroup}
                  selectedExercices={selectedExercices}
                  setSelectedExercices={setSelectedExercices}
                />
              ))}
            {selectedTab === 1 && (
              <Box w="full" pb={16}>
                {sortedFilteredPrograms.length > 0 && (
                  <Box>
                    <Text fontWeight={700} fontSize={16}>
                      Vos programmes
                    </Text>
                    <Flex
                      justifyContent="flex-start"
                      flexWrap="wrap"
                      gap="1%"
                      mt={4}
                    >
                      {sortedFilteredPrograms.map((program) => (
                        <ProgramCard
                          key={`program_${program.id}`}
                          program={program}
                          selectedProgram={selectedProgram}
                          setSelectedProgram={setSelectedProgram}
                        />
                      ))}
                    </Flex>
                  </Box>
                )}
                {sortedCommonPrograms.length > 0 && (
                  <Box>
                    <Text fontWeight={700} fontSize={16}>
                      Autres programmes
                    </Text>
                    <Flex
                      justifyContent="flex-start"
                      flexWrap="wrap"
                      gap="1%"
                      mt={4}
                    >
                      {sortedCommonPrograms.map((program) => (
                        <ProgramCard
                          key={`program_${program.id}`}
                          program={program}
                          selectedProgram={selectedProgram}
                          setSelectedProgram={setSelectedProgram}
                        />
                      ))}
                    </Flex>
                  </Box>
                )}
              </Box>
            )}
            {selectedTab === 2 &&
              sortedFilteredLikedVideoGroups.map((videoGroup) => (
                <VideoGroup
                  key={`liked_video_group_${videoGroup.id}`}
                  videoGroup={videoGroup}
                  fetchDataForReason={fetchDataForReason}
                  reason={reason}
                />
              ))}
          </Flex>
        </Box>
        {selectedExercices && (
          <SelectedExercices
            selectedExercices={selectedExercices}
            setSelectedExercices={setSelectedExercices}
            setStep={setStep}
          />
        )}
      </Flex>
      {selectedProgram && (
        <AddProgramModal
          selectedProgram={selectedProgram}
          setSelectedProgram={setSelectedProgram}
          selectedExercices={selectedExercices}
          setSelectedExercices={setSelectedExercices}
        />
      )}
    </Box>
  )
}

export default SelectExercices
