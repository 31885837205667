import { TimeIcon } from "@chakra-ui/icons"
import { Flex, Heading, Image, Text } from "@chakra-ui/react"
import { SelectedExerciceType } from "api/appointmentReason"
import { ProgramType, TrainingVideoPrograms } from "api/program"
import AButton from "components/AButton"
import React, { FC } from "react"
import { getTotalDuration } from "utils/getTotalDuration"

interface AddProgramModalProps {
  selectedProgram: ProgramType
  setSelectedProgram: React.Dispatch<React.SetStateAction<ProgramType | null>>
  selectedExercices?: SelectedExerciceType[]
  setSelectedExercices?: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
}

const AddProgramModal: FC<AddProgramModalProps> = ({
  selectedProgram,
  setSelectedProgram,
  selectedExercices,
  setSelectedExercices,
}) => {
  const handleAddProgram = () => {
    if (!selectedExercices || !setSelectedExercices) return
    const videosToAdd = selectedProgram.trainingVideoPrograms
      .map((trainingVideoProgram) => ({
        ...trainingVideoProgram.trainingVideo,
        duration: trainingVideoProgram.duration,
        videoGroup: trainingVideoProgram.trainingVideo.group[0].id,
      }))
      .filter(
        (video) =>
          selectedExercices.find((elt) => elt.id === video.id) === undefined
      )
    setSelectedExercices((prev) => [...prev, ...videosToAdd])
    setSelectedProgram(null)
  }

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      position="fixed"
      top="0px"
      left="0px"
      width="full"
      height="100vh"
      bg="rgba(0, 0, 0, 0.4)"
      zIndex={100}
    >
      <Flex
        bg="white"
        zIndex={100}
        p="32px"
        flexDirection="column"
        gap="32px"
        width="728px"
        rounded="8px"
        maxH="90vh"
        overflow="auto"
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "primary.300",
            borderRadius: "24px",
          },
        }}
      >
        <Flex justifyContent="space-between" w="full">
          <Heading as="h6" fontSize="18px" fontWeight={700}>
            {selectedProgram.title}
          </Heading>
          <Flex
            bg="common.200"
            px="8px"
            py="2px"
            gap={1}
            rounded="24px"
            fontSize="12px"
            alignItems="center"
          >
            <TimeIcon />
            <Text fontWeight={600}>
              {getTotalDuration(selectedProgram.trainingVideoPrograms)}
            </Text>
          </Flex>
        </Flex>

        <Flex justifyContent="flex-start" gap="12px" flexWrap="wrap">
          {selectedProgram.trainingVideoPrograms.map((trainingVideoProgram) => (
            <Flex
              key={trainingVideoProgram.id}
              flexDirection="column"
              maxWidth="200px"
            >
              <Image
                src={
                  trainingVideoProgram.trainingVideo.preview.formats.small.url
                }
                width="200px"
                rounded="8px"
                mb="8px"
              />
              <Text fontSize="18px" fontWeight={700}>
                {trainingVideoProgram.trainingVideo.group[0].title}
              </Text>
              <Text>niveau {trainingVideoProgram.trainingVideo.level}</Text>
            </Flex>
          ))}
          <Flex justifyContent="end" gap="16px" w="full" mt="16px">
            <AButton
              text={
                !selectedExercices || !setSelectedExercices
                  ? "Fermer"
                  : "Annuler"
              }
              variant="tertiary"
              onClick={() => {
                setSelectedProgram(null)
              }}
            />
            {selectedExercices && setSelectedExercices && (
              <AButton text="Ajouter" onClick={handleAddProgram} />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default AddProgramModal
