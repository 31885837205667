import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import React, { useState } from "react"
import AButton from "../../../../../../components/AButton"
import { SelectedExerciceType } from "api/appointmentReason"
import SelectedExerciceCard from "./components/SelectedExerciceCard"
import { TimeIcon } from "@chakra-ui/icons"
import formatDuration from "utils/formatDuration"
import { ZonePathologieType } from "pages/NewAppointment/newAppointment.mock"
import SelectedMainReason from "pages/NewAppointment/components/SelectExercices/components/SelectedExercices/components/SelectedMainReason"
import { getTotalDuration } from "utils/getTotalDuration"
import CreateProgramModal from "pages/NewAppointment/components/SelectExercices/components/SelectedExercices/components/CreateProgramModal"

interface IProps {
  selectedExercices: SelectedExerciceType[]
  setSelectedExercices: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
  setStep?: React.Dispatch<React.SetStateAction<number>>
  onNext?: () => void
  isCreatingProgram?: boolean
  isEditingAppointment?: boolean
}

const SelectedExercices: React.FC<IProps> = ({
  selectedExercices,
  setSelectedExercices,
  setStep,
  onNext,
  isCreatingProgram = false,
  isEditingAppointment = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onNextOrStepOver = () => {
    if (isCreatingProgram) {
      setIsModalOpen(true)
      return
    }
    if (setStep || isCreatingProgram) {
      if (setStep) setStep(2)
    } else if (onNext) {
      onNext()
    }
  }

  const textButton = setStep
    ? "Suivant"
    : isCreatingProgram
    ? "Créer le programme"
    : "Enregistrer les modifications"

  return (
    <div>
      {isCreatingProgram && isModalOpen && (
        <CreateProgramModal
          selectedExercices={selectedExercices}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      <Flex
        pos="sticky"
        top={4}
        maxH={"calc(100vh - 100px)"}
        w="320px"
        px={1}
        flexDirection="column"
        gap={4}
      >
        {!isEditingAppointment && (
          <Box
            w="full"
            h="100vh"
            pos="absolute"
            top="-100px"
            borderLeft="1px solid"
            borderColor="primary.300"
            left={-4}
            pointerEvents="none"
          />
        )}

        <Flex justifyContent="space-between">
          <Heading as={"h2"} fontSize="16px">
            Contenu du programme
          </Heading>
          {selectedExercices.length > 0 && (
            <Flex
              alignItems="center"
              gap={1}
              fontSize="14px"
              lineHeight="100%"
              bg="common.200"
              border="1px solid"
              borderColor="common.300"
              rounded="24px"
              py="4px"
              px="6px"
            >
              <TimeIcon />
              <Text>{getTotalDuration(selectedExercices)}</Text>
            </Flex>
          )}
        </Flex>

        {selectedExercices.length > 4 && (
          <Box p={4} bg="secondary.400" borderRadius={9} w="100%" left={0}>
            <Text fontSize={14}>
              Nous vous recommandons de limiter la sélection à 4 exercices. Cela
              optimise les chances de voir votre patient réaliser réellement sa
              séance
            </Text>
          </Box>
        )}
        {selectedExercices.length > 4 && (
          <Text fontSize={14} color="primary.700" ml={2}>
            Vous avez dépassé la limite de 4 exercices maximum recommandés.
          </Text>
        )}
        <Flex
          flexDir="column"
          gap={2}
          as="ul"
          aria-label="Liste des vidéos sélectionnées"
          overflowY="scroll"
          maxH="calc(100vh - 350px)"
        >
          {selectedExercices.length > 0 ? (
            selectedExercices.map((selectedExercice, i) => (
              <SelectedExerciceCard
                key={`${i}_${selectedExercice.id}`}
                selectedExercice={selectedExercice}
                setSelectedExercices={setSelectedExercices}
                position={i + 1}
                size={selectedExercices.length}
              />
            ))
          ) : (
            <Box
              p={"8px"}
              borderRadius={9}
              bg={"primary.200"}
              opacity={"1"}
              fontSize={14}
            >
              <Text fontWeight="bold">Aucune vidéo</Text>
              <Text>
                Cliquez sur les videos pour les ajouter dans la liste du
                programme, ou sélectionnez directement un de vos programmes.
              </Text>
            </Box>
          )}
        </Flex>
      </Flex>
      <Flex
        direction="column"
        align="center"
        justify="center"
        w="full"
        gap={2}
        fontFamily="Montserrat"
        bg="primary.200"
        py={6}
        position="fixed"
        bottom={0}
        left={0}
        zIndex={90}
      >
        <AButton
          text={textButton}
          px={120}
          py={3}
          fontFamily="Montserrat"
          onClick={onNextOrStepOver}
        />
      </Flex>
    </div>
  )
}

export default SelectedExercices
