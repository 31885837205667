import { Box, Flex, Text } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { curryIncludesInsensitive } from "utils/includesInsensitive"
import {
  getAppointmentReason,
  TrainingVideoGroupsType,
  VideoGroupCategoryType,
  VideoType,
} from "api/appointmentReason"
import {
  PATHOLOGIES_MOCK,
  ZonePathologieType,
  ZONES_MOCK,
} from "pages/NewAppointment/newAppointment.mock"
import VideoGroup from "./components/VideoGroup/VideoGroup"
import { ReasonType } from "api/patient"
import { getCommonPrograms, getMyPrograms, ProgramType } from "api/program"
import Reason from "pages/Exercices/components/ExercicesLists/components/Reason"
import ZonesPathologies from "pages/Exercices/components/ExercicesLists/components/ZonesPathologies/ZonesPathologies"
import TabExercices from "pages/Exercices/components/ExercicesLists/components/TabExecices/TabExercices"
import FilterPrograms from "pages/Exercices/components/ExercicesLists/components/FilterPrograms/FilterPrograms"
import ProgramCard from "pages/Exercices/components/ExercicesLists/components/ProgramCard/ProgramCard"
import AddProgramModal from "pages/NewAppointment/components/SelectExercices/components/AddProgramModal/AddProgramModal"
import { niceDisplayVideoGroup } from "utils/niceDisplayVideoGroup"

const sortByTimeUsed = (
  a: TrainingVideoGroupsType,
  b: TrainingVideoGroupsType
) => {
  if (a.timeUsed < b.timeUsed) {
    return 1
  }
  if (a.timeUsed > b.timeUsed) {
    return -1
  }
  return 0
}

export const VIDEOS_TAB = 0
export const PROGRAMME_TAB = 1
export const FAVORIS_TAB = 2

// COMPONENTS
interface IProps {
  reason?: ZonePathologieType
  setReason: React.Dispatch<
    React.SetStateAction<ZonePathologieType | undefined>
  >
  reasonType: "zones" | "pathologies" | null
  setReasonType: (reason: "zones" | "pathologies" | null) => void
}

const ExercicesLists: React.FC<IProps> = ({
  reason,
  reasonType,
  setReasonType,
  setReason,
}) => {
  const [search, setSearch] = useState("")
  const [suggestedProgramme, setSuggestedProgramme] = useState<VideoType[]>([])
  const [videoGroups, setVideoGroups] = useState<TrainingVideoGroupsType[]>([])
  const [categories, setCategories] = useState<VideoGroupCategoryType[]>([])
  const [categoryFilter, setCategoryFilter] = useState<string | null>(null)

  const [restrictionFilter, setRestrictionFilter] = useState<string | null>(
    null
  )
  const [programs, setPrograms] = useState<ProgramType[]>([])
  const [commonPrograms, setCommonPrograms] = useState<ProgramType[]>([])
  const [selectedProgram, setSelectedProgram] = useState<ProgramType | null>(
    null
  )
  const [likedVideoGroups, setLikedVideoGroups] = useState<
    TrainingVideoGroupsType[]
  >([])

  const [selectedTab, setSelectedTab] = useState(VIDEOS_TAB)
  const [sortedCommonPrograms, setSortedCommonPrograms] = useState<
    ProgramType[]
  >([])

  useEffect(() => {
    const filteredProgram = filterPrograms(commonPrograms)
    setSortedCommonPrograms(filteredProgram)
  }, [search, reason, commonPrograms])

  const restrictions = videoGroups.reduce(
    (acc, { restrictions }) => [
      ...acc,
      ...(restrictions ?? []).reduce(
        (restrictionsAcc, { name }) =>
          acc.includes(name) ? restrictionsAcc : [...restrictionsAcc, name],
        [] as string[]
      ),
    ],
    [] as string[]
  )

  const fetchDataForReason = async (reason: ReasonType | null) => {
    const { data } = await getAppointmentReason(reason?.id ?? -1)
    setSuggestedProgramme(data.suggestedProgramme)
    setVideoGroups(data.trainingVideoGroups)
    setLikedVideoGroups(
      data.trainingVideoGroups.filter(
        (trainingVideoGroup) =>
          trainingVideoGroup.osteosLiking &&
          trainingVideoGroup.osteosLiking.length > 0
      )
    )
  }

  const fetchMyPrograms = async () => {
    const programsResponse = await getMyPrograms()
    const commonProgramsResponse = await getCommonPrograms()
    setPrograms(programsResponse)
    setCommonPrograms(commonProgramsResponse)
  }

  useEffect(() => {
    fetchMyPrograms()
  }, [])

  useEffect(() => {
    fetchDataForReason(reason ?? null)
  }, [reason, selectedTab])

  useEffect(() => {
    const listOfCategories = videoGroups
      .map((videoGroup) => videoGroup.category)
      .filter((elt) => elt !== null)

    const uniqueCategories = listOfCategories.filter(
      (category, index) => listOfCategories.indexOf(category) === index
    )

    setCategories(uniqueCategories)
  }, [videoGroups])

  useEffect(() => {
    // reset filters
    setCategoryFilter(null)
    setSearch("")
  }, [reason])

  const filterWithCategoriesRestrictionsAndSearch = (
    list: TrainingVideoGroupsType[]
  ) => {
    const curriedIncludesInsensitive = curryIncludesInsensitive(search)
    let filteredList = list
    if (search !== "") {
      filteredList = filteredList.filter(({ title }) =>
        curriedIncludesInsensitive(title)
      )
    }
    if (categoryFilter !== null) {
      filteredList = filteredList.filter(
        ({ category }) => category === categoryFilter
      )
    }
    if (restrictionFilter !== null) {
      filteredList = filteredList.filter(({ restrictions }) =>
        restrictions.some(({ name }) => name === restrictionFilter)
      )
    }
    return filteredList
  }

  const filterPrograms = (list: ProgramType[]) => {
    const newList = list.filter(
      (program) =>
        program.trainingVideoPrograms &&
        program.trainingVideoPrograms.length > 0
    )
    const curriedIncludesInsensitive = curryIncludesInsensitive(search)
    let filteredList = newList
    if (search !== "") {
      filteredList = filteredList.filter(({ title }) =>
        curriedIncludesInsensitive(title)
      )
    }
    if (reason) {
      filteredList = filteredList.filter(({ appointmentReasons }) =>
        appointmentReasons.find(
          (currentReason) => currentReason.id === reason.id
        )
      )
    }
    return filteredList
  }

  const [sortedFilteredVideoGroups, setSortedFilteredVideoGroups] = useState<
    TrainingVideoGroupsType[]
  >([])
  const [sortedFilteredLikedVideoGroups, setSortedFilteredLikedVideoGroups] =
    useState<TrainingVideoGroupsType[]>([])

  const [sortedFilteredPrograms, setSortedFilteredPrograms] = useState<
    ProgramType[]
  >([])

  useEffect(() => {
    setSortedFilteredVideoGroups(
      niceDisplayVideoGroup(
        filterWithCategoriesRestrictionsAndSearch(
          videoGroups.sort(sortByTimeUsed)
        )
      )
    )
    setSortedFilteredLikedVideoGroups(
      niceDisplayVideoGroup(
        filterWithCategoriesRestrictionsAndSearch(
          likedVideoGroups.sort(sortByTimeUsed)
        )
      )
    )
  }, [videoGroups, categoryFilter, restrictionFilter, search])

  useEffect(() => {
    const filteredPrograms = filterPrograms(programs)
    setSortedFilteredPrograms(filteredPrograms)
  }, [search, reason, programs])

  return (
    <Flex direction="column" gap="16px" position="relative" width="full">
      <TabExercices
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        isPageExercies
      />
      <Box
        position="absolute"
        w="calc(100% + 100px)"
        left="-50px"
        h="1px"
        top="40px"
        bg="primary.200"
      ></Box>
      {/* <Flex gap="93px"> */}
      <Flex gap={"10px"}>
        <Box flex={1} position="relative">
          {/* {selectedTab === 0 && (
            <FilterExercices
              categories={categories}
              categoryFilter={categoryFilter}
              setCategoryFilter={setCategoryFilter}
              setSearch={setSearch}
              restrictions={restrictions}
              restrictionFilter={restrictionFilter}
              setRestrictionFilter={setRestrictionFilter}
              reasons={reasons}
              allReasons={allReasons}
              setCurrentReason={setCurrentReason}
            />
          )} */}
          {selectedTab === 1 && <FilterPrograms setSearch={setSearch} />}

          <Flex justifyContent="flex-start" flexWrap="wrap" gap="16px">
            {selectedTab === 0 &&
              sortedFilteredVideoGroups.map((videoGroup, i) => (
                <VideoGroup
                  key={`group_${videoGroup.id}_${i}`}
                  videoGroup={videoGroup}
                />
              ))}
            {selectedTab === 1 && (
              <Box w="full" pb={16}>
                {sortedFilteredPrograms.length > 0 && (
                  <Box>
                    <Text fontWeight={700} fontSize={16}>
                      Vos programmes
                    </Text>
                    <Flex
                      justifyContent="flex-start"
                      flexWrap="wrap"
                      gap="1%"
                      mt={4}
                    >
                      {sortedFilteredPrograms.map((program) => (
                        <ProgramCard
                          key={`program_${program.id}`}
                          program={program}
                          selectedProgram={selectedProgram}
                          setSelectedProgram={setSelectedProgram}
                          isLooking
                        />
                      ))}
                    </Flex>
                  </Box>
                )}
                {sortedCommonPrograms.length > 0 && (
                  <Box>
                    <Text fontWeight={700} fontSize={16}>
                      Autres programmes
                    </Text>
                    <Flex
                      justifyContent="flex-start"
                      flexWrap="wrap"
                      gap="1%"
                      mt={4}
                    >
                      {sortedCommonPrograms.map((program) => (
                        <ProgramCard
                          key={`program_${program.id}`}
                          program={program}
                          selectedProgram={selectedProgram}
                          setSelectedProgram={setSelectedProgram}
                          isLooking
                        />
                      ))}
                    </Flex>
                  </Box>
                )}
              </Box>
            )}

            {selectedTab === 2 &&
              sortedFilteredLikedVideoGroups.map((videoGroup) => (
                <VideoGroup
                  key={`group_${videoGroup.id}`}
                  videoGroup={videoGroup}
                  fetchDataForReason={fetchDataForReason}
                  reason={reason}
                />
              ))}
          </Flex>
        </Box>
        <Flex direction="column" pos="sticky" top={4} pl="24px" h="fit-content">
          <Box
            position="absolute"
            h="calc(100vh)"
            left="0px"
            w="1px"
            top="-16px"
            bg="primary.200"
          ></Box>
          <Reason reason={reasonType} setReason={setReasonType} />
          <Flex alignItems={"center"}>
            <Flex justifyContent="center" w="full">
              {reasonType === "zones" && (
                <ZonesPathologies
                  datas={ZONES_MOCK}
                  selectedData={reason}
                  setSelectedData={setReason}
                />
              )}
              {reasonType === "pathologies" && (
                <ZonesPathologies
                  datas={PATHOLOGIES_MOCK}
                  selectedData={reason}
                  setSelectedData={setReason}
                />
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {selectedProgram && (
        <AddProgramModal
          selectedProgram={selectedProgram}
          setSelectedProgram={setSelectedProgram}
        />
      )}
    </Flex>
  )
}

export default ExercicesLists
