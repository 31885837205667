import {
  SelectedDiscoverType,
  SelectedExerciceType,
} from "api/appointmentReason"
import { ZonePathologieType } from "pages/NewAppointment/newAppointment.mock"
import React, { useEffect, useState } from "react"
import { PatientType, TraininScheduleType } from "../../api/patient"
import SelectExercices from "./components/SelectExercices/SelectExercices"
import SelectReason from "./components/SelectReason/SelectReason"
import SelectSchedule, {
  ReminderDataType,
} from "./components/SelectSchedule/SelectSchedule"
import SelectDiscovers from "pages/NewAppointment/components/SelectDiscovers/SelectDiscovers"
import { BabyMediaTag } from "api/media"
import { useAppContext } from "AppContext"
import getCurrentScopeParams from "utils/getCurrentScopeParams"
import { useMutation } from "@tanstack/react-query"
import { newAppointment } from "api/appointments"
import { format } from "date-fns"
import { useNavigate } from "react-router-dom"
import useToast from "hooks/useToast"

const NewAppointment = () => {
  const [step, setStep] = useState(0)
  const [patient, setPatient] = React.useState<PatientType | undefined>(
    undefined
  )
  const [advice, setAdvice] = React.useState<string | null>(null)
  const [internalNote, setInternalNote] = React.useState<string | null>(null)
  const [secondaryInternalNote, setSecondaryInternalNote] = React.useState<
    string | null
  >(null)
  const [pain, setPain] = React.useState<undefined | number>(undefined)
  const [frequency, setFrequency] = React.useState<undefined | number>(
    undefined
  )
  const [mobility, setMobility] = React.useState<undefined | number>(undefined)
  const [stress, setStress] = React.useState<undefined | number>(undefined)
  const [reasonType, setReasonType] = useState<"zones" | "pathologies" | null>(
    "zones"
  )
  const [reason, setReason] = useState<ZonePathologieType>()
  const [selectedExercices, setSelectedExercices] = useState<
    SelectedExerciceType[]
  >([])
  const [selectedDiscovers, setSelectedDiscovers] = useState<
    SelectedDiscoverType[]
  >([])
  const [trainingSchedule, setTrainingSchedule] = React.useState<
    TraininScheduleType[]
  >(patient?.trainingSchedule || [])
  const [isBabySwitchOn, setIsBabySwitchOn] = useState(false)
  const [selectedBabyMediaTag, setSelectedBabyMediaTag] =
    useState<BabyMediaTag | null>(null)
  const babyMediaTags = [
    { key: "bebe_tete", name: "Crâne" },
    { key: "bebe_ventre", name: "Système digestif" },
    { key: "bebe_portage", name: "Portage" },
    { key: "bebe_moteur", name: "Éveil et développement moteur" },
  ]

  useEffect(() => {
    setTrainingSchedule(patient?.trainingSchedule || [])
  }, [patient])

  const setReasonAndReset = (newReasonType: "zones" | "pathologies" | null) => {
    setReasonType(newReasonType)
  }

  const { user, currentScope } = useAppContext()
  const newAppointmentExtraParams = getCurrentScopeParams(currentScope)
  const newAppointmentMutation = useMutation(newAppointment)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()
  const toast = useToast()

  const handleNewAppointment = (
    reminderData?: ReminderDataType | undefined
  ) => {
    setIsSubmitting(true)
    const dateInTwoMonths = new Date()
    dateInTwoMonths.setMonth(dateInTwoMonths.getMonth() + 2)

    const allDefined =
      pain !== undefined &&
      stress !== undefined &&
      frequency !== undefined &&
      mobility !== undefined

    if (!user || !patient?.id) {
      setIsSubmitting(false)
      return
    }

    // const checkup = hasCheckupData
    //   ? {
    //       patient: patient.id,
    //       pain, // Toutes les valeurs doivent être définies ici
    //       stress: stress as number,
    //       frequency: frequency as number,
    //       mobility: mobility as number,
    //     }
    //   : undefined

    const checkup = allDefined
      ? {
          patient: patient.id,
          pain,
          stress,
          frequency,
          mobility,
        }
      : undefined

    const playlist = {
      author: user?.osteo.id,
      expiry: format(dateInTwoMonths, "yyyy-MM-dd"),
    }

    const explorerMedias =
      selectedDiscovers.length === 0
        ? null
        : selectedDiscovers.map(({ id }) => id)

    // const checkup = {
    //   patient: patient.id,
    //   pain,
    //   stress,
    //   frequency,
    //   mobility,
    // }

    newAppointmentMutation.mutate(
      {
        playlist,
        appointment: {
          osteo: user?.osteo.id,
          ...newAppointmentExtraParams,
          patient: patient.id,
          osteoAdvice:
            advice ||
            `Pensez à faire régulièrement vos exercices pour maximiser les effets de votre consultation.`,
          meetingDate: format(new Date(), "yyyy-MM-dd"),
          internalNote,
          secondaryInternalNote,
          explorerMedias,
          reminderScheduledDate: reminderData ? reminderData.dateTime : null,
          reminderMessage: reminderData ? reminderData.text : null,
        },
        videoExercices: selectedExercices.map((exercice) => ({
          duration: exercice.duration,
          videoGroup: exercice.videoGroup,
          osteoSelection: exercice.id,
        })),
        checkup,
        trainingSchedule,
        patientId: patient.id,
      },
      {
        onSuccess: ({ id }) => {
          navigate(`/patients/${patient.id}/appointments/${id}`)
          toast({
            status: "success",
            title: "Consultation ajoutée avec succès",
          })
          setIsSubmitting(false)
        },
        onError: () => {
          toast({
            status: "error",
            title: "Une erreur est survenue",
          })
          setIsSubmitting(false)
        },
      }
    )
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step])
  if (step === 0)
    return (
      <SelectReason
        patient={patient}
        setPatient={setPatient}
        setStep={setStep}
        advice={advice}
        setAdvice={setAdvice}
        pain={pain}
        setPain={setPain}
        stress={stress}
        setStress={setStress}
        frequency={frequency}
        setFrequency={setFrequency}
        mobility={mobility}
        setMobility={setMobility}
        internalNote={internalNote}
        setInternalNote={setInternalNote}
        secondaryInternalNote={secondaryInternalNote}
        setSecondaryInternalNote={setSecondaryInternalNote}
        isBabySwitchOn={isBabySwitchOn}
        setIsBabySwitchOn={setIsBabySwitchOn}
      />
    )

  if (step === 1)
    return (
      <SelectExercices
        setStep={setStep}
        selectedExercices={selectedExercices}
        setSelectedExercices={setSelectedExercices}
        reason={reason}
        reasonType={reasonType}
        setReasonType={setReasonAndReset}
        setReason={setReason}
        isBabySwitchOn={isBabySwitchOn}
        setIsBabySwitchOn={setIsBabySwitchOn}
        selectedBabyMediaTag={selectedBabyMediaTag}
        setSelectedBabyMediaTag={setSelectedBabyMediaTag}
        babyMediaTags={babyMediaTags}
      />
    )

  if (step === 2)
    return (
      <SelectDiscovers
        setStep={setStep}
        reason={reasonType}
        selectedDiscovers={selectedDiscovers}
        setSelectedDiscovers={setSelectedDiscovers}
        isBabySwitchOn={isBabySwitchOn}
        selectedBabyMediaTag={selectedBabyMediaTag}
        setSelectedBabyMediaTag={setSelectedBabyMediaTag}
        babyMediaTags={babyMediaTags}
        handleNewAppointment={handleNewAppointment}
        isSubmitting={isSubmitting}
      />
    )

  return (
    <SelectSchedule
      setStep={setStep}
      trainingSchedule={trainingSchedule}
      setTrainingSchedule={setTrainingSchedule}
      handleNewAppointment={handleNewAppointment}
      isSubmitting={isSubmitting}
      user={user}
    />
  )
}

export default NewAppointment
