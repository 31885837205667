import { AddIcon, TimeIcon, ViewIcon } from "@chakra-ui/icons"
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react"
import { ProgramType } from "api/program"
import AButton from "components/AButton"
import React, { FC, useState } from "react"
import formatDuration from "utils/formatDuration"
import { getTotalDuration } from "utils/getTotalDuration"

interface ProgramCardProps {
  program: ProgramType
  selectedProgram: ProgramType | null
  setSelectedProgram: React.Dispatch<React.SetStateAction<ProgramType | null>>
  isLooking?: boolean
}

const ProgramCard: FC<ProgramCardProps> = ({
  program,
  selectedProgram,
  setSelectedProgram,
  isLooking = false,
}) => {
  const [isHovering, setIsHovering] = useState(false)
  return (
    <Flex direction="column" w="calc(33% - 16px)" gap="8px" mb={6}>
      <Box
        position="relative"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {isHovering && (
          <Flex
            bg="rgba(17, 23, 45, 0.5)"
            pos="absolute"
            top={0}
            left={0}
            w="100%"
            h="100%"
            alignItems="center"
            justifyContent="center"
          >
            <AButton
              text={isLooking ? "Détail" : "Ajouter"}
              variant="tertiary"
              fontSize={12}
              leftIcon={isLooking ? <ViewIcon /> : <AddIcon />}
              onClick={() => setSelectedProgram(program)}
            />
          </Flex>
        )}
        {program.trainingVideoPrograms.length > 0 && (
          <Image
            src={
              program.trainingVideoPrograms[0].trainingVideo.preview.formats
                .small.url
            }
            rounded="8px"
            w="100%"
            h="155px"
            objectFit="cover"
          />
        )}
        <Flex
          position="absolute"
          alignItems="center"
          bg="common.100"
          px="8px"
          py="2px"
          gap={1}
          rounded="24px"
          fontSize="12px"
          right="10px"
          top="10px"
        >
          <TimeIcon />
          <Text fontWeight={600}>
            {getTotalDuration(program.trainingVideoPrograms)}
          </Text>
        </Flex>
        <Flex gap="8px" position="absolute" bottom="8px" left="8px" w="full">
          {program.trainingVideoPrograms.map((trainingVideo, index) => {
            if (index > 0 && index <= 3) {
              return (
                <Image
                  w="20%"
                  key={trainingVideo.id}
                  src={trainingVideo.trainingVideo.preview.formats.small.url}
                  rounded="4px"
                  border="1px solid"
                  borderColor="white"
                />
              )
            }
            return null
          })}
          {program.trainingVideoPrograms.length > 4 && (
            <Flex
              w="12%"
              bg="white"
              justifyContent="center"
              alignItems="center"
              rounded="4px"
            >
              <Text fontWeight={700}>
                +{program.trainingVideoPrograms.length - 4}
              </Text>
            </Flex>
          )}
        </Flex>
      </Box>
      <Heading as="h6" fontSize="18px" lineHeight="100%" flex={1}>
        {program.title}
      </Heading>
      <Text lineHeight="100%">niveau {program.level}</Text>
    </Flex>
  )
}

export default ProgramCard
