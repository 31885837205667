import { TopicFullListType } from "api/topics"
import axiosInstance from "./axiosInstance"
import { MediaType } from "api/media"

export type VideoPreviewType = {
  id: number
  alternativeText: string
  formats: {
    small: {
      url: string
    }
  }
}

export type VideoContentType = {
  id: number
  url: string
  caption: string
}

export type VideoBunnyCDNType = {
  fullVideo: string
  loopVideo: string
  preview: string
  previewWebP: string
}

export type VideoType = {
  id: number
  level: number
  limitedMode: boolean
  title: string
  preview: VideoPreviewType
  fullVideo: VideoContentType
  defaultDuration?: number
  group: TrainingVideoGroupsLightType[]
  videoGroup: number
  bunnyCDN?: VideoBunnyCDNType
}

export type SelectedExerciceType = VideoType & {
  duration: number
}

export type SelectedDiscoverType = MediaType

export type VideoGroupCategoryType = "souplesse" | "renfo" | "mobilite" | "nerf"
export type VideoGroupRestriction = {
  id: number
  name: string
}

export type TrainingVideoGroupsLightType = {
  id: number
  title: string
}

export type TrainingVideoGroupsType = TrainingVideoGroupsLightType & {
  videos: VideoType[]
  category: VideoGroupCategoryType
  restrictions: VideoGroupRestriction[]
  timeUsed: number
  osteosLiking?: number[]
}

export type AppointmentReasonType = {
  id: number
  internalId: number
  name: string
  trainingVideoGroups: TrainingVideoGroupsType[]
  suggestedProgramme: VideoType[]
}

export const getAppointmentReason = async (id: number) => {
  const response = await axiosInstance.get<AppointmentReasonType>(
    `/osteo/me/appointmentReason/${id}`
  )
  return response
}
