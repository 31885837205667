import { Box, Checkbox, Flex, Text, useDisclosure } from "@chakra-ui/react"
import React, { useEffect, useRef, useState } from "react"
import { SelectedDiscoverType } from "api/appointmentReason"
import Header from "./components/Header/Header"
import { TopicFullListType, getTopics } from "api/topics"
import DiscoverMedia from "pages/NewAppointment/components/SelectDiscovers/components/DiscoverMedia/DiscoverMedia"
import {
  getAllMedias,
  BabyMediaTag,
  MediaType,
  BabyMediaTagListType,
} from "api/media"
import SelectedDiscovers from "pages/NewAppointment/components/SelectDiscovers/components/SelectedDiscovers/SelectedDiscovers"
import AButton from "components/AButton"
import SuggestedVideo from "pages/NewAppointment/components/SelectDiscovers/components/SuggestedVideo/SuggestedVideo"
import GianniModal from "pages/NewAppointment/components/SelectDiscovers/components/GianniModal/GianniModal"
import { ReminderDataType } from "pages/NewAppointment/components/SelectSchedule/SelectSchedule"
import BabyDiscoverMedia from "pages/NewAppointment/components/SelectDiscovers/components/BabyDiscoverMedia/BabyDiscoverMedia"

interface IProps {
  setStep: React.Dispatch<React.SetStateAction<number>>
  reason?: "zones" | "pathologies" | null
  selectedDiscovers: SelectedDiscoverType[]
  setSelectedDiscovers: React.Dispatch<
    React.SetStateAction<SelectedDiscoverType[]>
  >
  isBabySwitchOn: boolean
  selectedBabyMediaTag: BabyMediaTag | null
  setSelectedBabyMediaTag: React.Dispatch<
    React.SetStateAction<BabyMediaTag | null>
  >
  babyMediaTags: BabyMediaTag[]
  handleNewAppointment: (reminderData?: ReminderDataType | undefined) => void
  isSubmitting: boolean
}

const SelectDiscovers: React.FC<IProps> = ({
  setStep,
  reason,
  selectedDiscovers,
  setSelectedDiscovers,
  isBabySwitchOn,
  selectedBabyMediaTag,
  setSelectedBabyMediaTag,
  babyMediaTags,
  handleNewAppointment,
  isSubmitting,
}) => {
  const [discovers, setDiscovers] = useState<TopicFullListType[]>([])
  const [mediasByTag, setMediaByTag] = useState<BabyMediaTagListType[]>([])
  const [selectedDiscover, setSelectedDiscover] = useState(0)

  const filteredDiscovers = discovers.filter(
    (discover) => discover.medias.length > 0
  )

  const cancelRef = useRef(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  useEffect(() => {
    fetchData()
  }, [selectedBabyMediaTag])

  const fetchData = async () => {
    const topics = await getTopics()
    const medias = await getAllMedias(isBabySwitchOn)

    if (isBabySwitchOn) {
      const mediaByTag: BabyMediaTagListType[] =
        selectedBabyMediaTag === null
          ? babyMediaTags.map((tag) => {
              const tagMedias = medias.filter(
                (media) => media.tag && media.tag.key === tag.key
              )
              return { tag, medias: tagMedias }
            })
          : medias
              .filter(
                (media) =>
                  media.tag && media.tag.key === selectedBabyMediaTag.key
              )
              .map((media) => ({
                tag: selectedBabyMediaTag,
                medias: [media],
              }))

      const mediasWithoutTag = medias.filter((media) => !media.tag)
      mediaByTag.push({
        tag: { key: "other", name: "Autre" },
        medias: mediasWithoutTag,
      })

      setMediaByTag(mediaByTag)
      return
    }

    const discovers: TopicFullListType[] = topics.map((topic) => ({
      id: topic.id,
      attributes: topic.attributes,
      medias: medias.filter(
        (media) => media.topic && media.topic.id === topic.id
      ),
    }))

    setDiscovers(discovers)
  }

  const handleAddAllMedias = (medias: MediaType[]) => {
    const newMedias = medias.filter(
      (media) => !selectedDiscovers.find((discover) => discover.id === media.id)
    )
    setSelectedDiscovers([...selectedDiscovers, ...newMedias])
  }

  useEffect(() => {
    fetchData()
  }, [])

  // if (discovers.length === 0 && mediasByTag.length === 0) {
  //   return (
  //     <Header setStep={setStep} reason={reason} isForBaby={isBabySwitchOn} />
  //   )
  // }
  if (discovers.length === 0 && mediasByTag.length === 0) {
    return (
      <>
        <Header setStep={setStep} reason={reason} isForBaby={isBabySwitchOn} />
        <Flex
          direction="column"
          align="center"
          justify="center"
          w="full"
          gap={2}
          fontFamily="Montserrat"
          bg="primary.200"
          py={6}
          position="fixed"
          bottom={0}
          left={0}
          zIndex={100}
        >
          <AButton
            text={isBabySwitchOn ? "Créer la consultation" : "Suivant"}
            px={120}
            py={3}
            onClick={() => {
              if (isBabySwitchOn) {
                handleNewAppointment()
                return
              }
              setStep(3)
            }}
            isDisabled={isSubmitting}
          />
        </Flex>
      </>
    )
  }

  return (
    <Flex p="16px" pb="128px" w={"full"}>
      <Flex direction={"column"} gap={"24px"} w={"full"}>
        <Header setStep={setStep} reason={reason} isForBaby={isBabySwitchOn} />
        <Flex w={"full"}>
          {isBabySwitchOn ? (
            <Flex gap={16} w="full">
              <Flex
                direction={"column"}
                position="sticky"
                top="16px"
                h="fit-content"
                w={"full"}
                maxW={"300px"}
              ></Flex>
              <Flex direction={"column"} w="full">
                <Box w={"full"}>
                  <SuggestedVideo
                    title="Introduction aux conseils nourrisson avec Gianni Marangelli"
                    url="https://www.youtube.com/embed/TSp-3b1lXAM?si=DJ2txxyEV_Q8rVXa"
                  />
                </Box>
                <Box mb={12}>
                  <Text mb={2} fontWeight="bold">
                    Le contenu nourrisson a été créé avec Gianni Marangelli.
                  </Text>
                  <Text>
                    <Text
                      textDecor={"underline"}
                      onClick={onOpen}
                      cursor="pointer"
                      display={"inline"}
                    >
                      En savoir plus
                    </Text>{" "}
                    sur Gianni Marangelli et ses références de travail.
                  </Text>
                </Box>
                <Flex direction={"column"} gap={"32px"} w={"full"}>
                  {mediasByTag.length > 0 &&
                    mediasByTag.map(
                      (mediaTag) =>
                        mediaTag.medias.length > 0 && (
                          <Flex
                            w={"full"}
                            key={mediaTag.tag.key}
                            direction={"column"}
                            alignItems={"start"}
                            gap={4}
                          >
                            <Text fontWeight={700} fontSize={24}>
                              {mediaTag.tag.name}
                            </Text>

                            <Flex
                              direction="row"
                              flexWrap="wrap"
                              gap="16px"
                              w={"full"}
                            >
                              {mediaTag.medias.map((media) => (
                                <BabyDiscoverMedia
                                  key={media.id}
                                  media={media}
                                  selectedDiscovers={selectedDiscovers}
                                  setSelectedDiscovers={setSelectedDiscovers}
                                />
                              ))}
                            </Flex>
                          </Flex>
                        )
                    )}
                </Flex>
              </Flex>
            </Flex>
          ) : (
            <Flex w="full" justify={"space-between"} gap={"24px"}>
              <Flex
                justify={"start"}
                maxW={"340px"}
                w="full"
                pos={"relative"}
                align={"center"}
                h={"calc(100vh - 358px)"} //remove size of header and footer
              >
                <Flex
                  direction={"column"}
                  backgroundColor={"primary.300"}
                  gap={"9px"}
                  borderRadius={"8px"}
                  align={"center"}
                  padding={"10px"}
                  h={"fit-content"}
                  position="sticky"
                  left={"16px"}
                  zIndex={100}
                >
                  {filteredDiscovers.map((discover, index) => (
                    <Flex
                      key={discover.id}
                      backgroundColor={
                        index === selectedDiscover ? "white" : "none"
                      }
                      borderRadius={"4px"}
                      padding={"10px"}
                      fontWeight={"bold"}
                      fontSize={"14px"}
                      textColor={"common.500"}
                      w={"150px"}
                      justify={"center"}
                      onClick={() => setSelectedDiscover(index)}
                      cursor={"pointer"}
                      textAlign={"center"}
                    >
                      {discover.attributes.title}
                    </Flex>
                  ))}
                </Flex>
              </Flex>
              <Flex
                direction={"column"}
                w={"full"}
                fontSize={14}
                textColor="black"
                minW={"540px"}
              >
                <Flex
                  backgroundColor={"white"}
                  padding={"8px"}
                  gap={"7px"}
                  align={"center"}
                >
                  <Checkbox
                    colorScheme="orange"
                    borderColor="black"
                    onChange={(e) =>
                      handleAddAllMedias(
                        filteredDiscovers[selectedDiscover].medias
                      )
                    }
                  />
                  <Text fontWeight={700} textAlign={"start"}>
                    Tout sélectionner
                  </Text>
                </Flex>
                {filteredDiscovers[selectedDiscover].medias.map(
                  (media, index) => (
                    <DiscoverMedia
                      key={media.id}
                      media={media}
                      index={index}
                      selectedDiscovers={selectedDiscovers}
                      setSelectedDiscovers={setSelectedDiscovers}
                    />
                  )
                )}
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>

      <Flex w={"420px"}>
        {selectedDiscovers && (
          <SelectedDiscovers
            selectedDiscovers={selectedDiscovers}
            setSelectedDiscovers={setSelectedDiscovers}
          />
        )}
      </Flex>
      <Flex
        direction="column"
        align="center"
        justify="center"
        w="full"
        gap={2}
        fontFamily="Montserrat"
        bg="primary.200"
        py={6}
        position="fixed"
        bottom={0}
        left={0}
        zIndex={100}
      >
        <AButton
          text={isBabySwitchOn ? "Créer la consultation" : "Suivant"}
          px={120}
          py={3}
          onClick={() => {
            if (isBabySwitchOn) {
              handleNewAppointment()
              return
            }
            setStep(3)
          }}
          isDisabled={isSubmitting}
        />
      </Flex>
      <GianniModal cancelRef={cancelRef} isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
}

export default SelectDiscovers
